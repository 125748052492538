import React from 'react';
import { Card } from 'react-bootstrap';

const RecentNews = ({ newsData }) => {
    if (!newsData || newsData.length === 0) {
        return <p>No recent news available.</p>;
    }

    return (
        <Card className="mb-4">
            <Card.Body className="text-start">
                <h3>Recent News</h3>
                <ul className="list-unstyled">
                    {newsData.map((news, index) => (
                        <li key={index} className="mb-4">
                            <a 
                                href={news.url} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                style={{ fontSize: '1.8rem', fontWeight: 'bold' }} // Increased size for the headline
                            >
                                {news.headline || 'No headline'}
                            </a>
                            {news.author && (
                                <span> - {news.author}</span>
                            )}
                            {news.created_at && (
                                <span> ({new Date(news.created_at).toLocaleDateString()})</span>
                            )}

                            {/* Render content as HTML under the headline */}
                            {news.content && (
                                <div 
                                    dangerouslySetInnerHTML={{ __html: news.content }} 
                                    className="mt-2"
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </Card.Body>
        </Card>
    );
};

export default RecentNews;
