import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StockChart = ({ stockData, height }) => {
    if (!stockData || stockData.length === 0) {
        return <p>No stock data available for chart.</p>;
    }

    // Assuming stockData has Date and Close fields
    const data = stockData.map(item => ({
        date: item.Date,
        price: item.Close,
    }));

    return (
        <div style={{ height: '100%' }}> {/* Ensure the container takes the full height */}
            <h3>Stock Price Chart</h3>
            <ResponsiveContainer width="100%" height={height}> {/* Pass the height as a prop */}
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default StockChart;
