import React, { useState, useEffect, useRef } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ChatBox = ({ ticker }) => {
    const [chatMessage, setChatMessage] = useState('');
    const [chatResponse, setChatResponse] = useState('');
    const chatBoxRef = useRef(null);  // Ref for the chat display area

    const handleChatSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`/api/stock/${ticker}`, {
                message: chatMessage,
                ticker: ticker,
            });
            setChatResponse(prevResponse => `${prevResponse}\n\n${response.data.response}`);
            setChatMessage('');
        } catch (err) {
            setChatResponse('Error occurred while fetching chat response.');
        }
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;  // Scroll only the chat box
        }
    };

    useEffect(() => {
        scrollToBottom();  // Scroll to bottom whenever chatResponse updates
    }, [chatResponse]);

    return (
        <Card className="mb-4" style={{ height: '700px', display: 'flex', flexDirection: 'column' }}>
            <Card.Body style={{ padding: '0', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <h4 className="p-3">Stockato AI</h4>
                {/* Chat display area */}
                <div 
                    ref={chatBoxRef}  // Attach the ref to the scrollable chat area
                    style={{
                        flexGrow: 1,
                        overflowY: 'auto',  // Enable scrolling for the chat content
                        padding: '1rem',
                        borderBottom: '1px solid #ccc',
                        maxHeight: '500px',  // Fixed height for the chat area
                    }}
                >
                    {chatResponse ? (
                        <div className="chat-response">
                            <strong>Bot:</strong>
                            <p style={{ whiteSpace: 'pre-wrap' }}>{chatResponse}</p>
                        </div>
                    ) : (
                        <p>No messages yet.</p>
                    )}
                </div>
                {/* Chat input form at the bottom */}
                <Form onSubmit={handleChatSubmit} className="p-3">
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Ask a question about the stock"
                            value={chatMessage}
                            onChange={(e) => setChatMessage(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">Send</Button>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ChatBox;
