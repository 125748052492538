import React, { useState } from 'react';
import axios from 'axios';
import StockInformation from './StockInformation';
import RecentNews from './RecentNews';
import StockChart from './StockChart';
import ChatBox from './ChatBox';  // Import ChatBox component
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

const StockSearch = () => {
    const [ticker, setTicker] = useState('');
    const [stockData, setStockData] = useState(null);
    const [analysisHtml, setAnalysisHtml] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.get(`/api/stock/${ticker}`);
            console.log("API Response:", response.data);
            setStockData(response.data);
            setAnalysisHtml(formatAnalysisHtml(response.data.analysis_text));
            setError(null);
        } catch (err) {
            setError('Failed to fetch stock data');
            setStockData(null);
            setAnalysisHtml('');
        } finally {
            setLoading(false);
        }
    };

    const formatAnalysisHtml = (html) => {
        return html.split('\n').map(comment => `<p>${comment}</p>`).join('');
    };

    return (
        <Container fluid className="p-4">
            {/* Search bar row */}
            <Row>
                <Col md={3}>
                    <Form onSubmit={handleSearch} className="d-flex align-items-center">
                        <Form.Control
                            type="text"
                            value={ticker}
                            onChange={(e) => setTicker(e.target.value)}
                            placeholder="Enter stock ticker"
                            required
                            className="me-2"
                        />
                        <Button variant="primary" type="submit">
                            Search
                        </Button>
                    </Form>
                </Col>
            </Row>

            {/* Row with Stock Chart and Chat Module */}
            <Row className="mt-4">
                <Col md={8}> {/* Vertically increased chart size */}
                    {stockData && (
                        <div style={{ height: '700px' }}> {/* Increased height to 700px */}
                            <StockChart stockData={stockData.stock_data} />
                        </div>
                    )}
                </Col>

                <Col md={4}> 
                    {/* Pass the current ticker to the ChatBox */}
                    <ChatBox ticker={ticker} />
                </Col>
            </Row>

            {/* Stock Information and Reddit Analysis row */}
            {stockData && (
                <Row className="mt-4">
                    <Col md={8} className="text-start">
                        {/* Separated into Stock Information and Recent News */}
                        <StockInformation stats={stockData.stats} bs={stockData.bs} />
                        <RecentNews newsData={stockData.news_data} />
                    </Col>
                    <Col md={4} className="text-start">
                        {analysisHtml && (
                            <div>
                                <h3>Reddit Analysis</h3>
                                <div
                                    className="reddit-comments"
                                    dangerouslySetInnerHTML={{ __html: analysisHtml }}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            )}

            {loading && <p className="text-center">Loading...</p>}
            {error && <p className="text-danger text-center">{error}</p>}
        </Container>
    );
};

export default StockSearch;
