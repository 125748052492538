import React from 'react';
import { Card, Row, Col, Tabs, Tab, Table } from 'react-bootstrap';

const StockInformation = ({ stats, bs }) => {
    console.log('bs prop:', bs);
    console.log('stats prop:', stats);
    if (!stats || !stats.Name) {
        return <p>No stock information available.</p>;
    }

    // Helper function to format numbers as currency
    const formatCurrency = (value) => {
        if (value && !isNaN(parseFloat(value))) {
            return `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }
        return 'N/A';
    };

    return (
        <Card className="mb-4">
            <Card.Body className="text-start">
                <h2>Stock Information</h2>

                {/* Tabs for Fundamentals and Options */}
                <Tabs defaultActiveKey="fundamentals" id="stock-info-tabs" className="mb-3">
                    <Tab eventKey="fundamentals" title="Fundamentals">
                        {/* Fundamentals Section */}
                        <Row className="mb-3">
                            <Col><p><strong>Company Name:</strong> {stats.Name}</p></Col>
                            <Col><p><strong>Symbol:</strong> {stats.Symbol}</p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p><strong>Description:</strong> {stats.Description}</p></Col>
                        </Row>

                        <Row>
                            <Col md={4}><p><strong>Exchange:</strong> {stats.Exchange}</p></Col>
                            <Col md={4}><p><strong>Currency:</strong> {stats.Currency}</p></Col>
                            <Col md={4}><p><strong>Country:</strong> {stats.Country}</p></Col>
                        </Row>

                        <Row>
                            <Col md={4}><p><strong>Sector:</strong> {stats.Sector}</p></Col>
                            <Col md={4}><p><strong>Industry:</strong> {stats.Industry}</p></Col>
                            <Col md={4}><p><strong>Market Cap:</strong> {formatCurrency(stats.MarketCapitalization)}</p></Col>
                        </Row>

                        <Row>
                            <Col md={4}><p><strong>EBITDA:</strong> {formatCurrency(stats.EBITDA)}</p></Col>
                            <Col md={4}><p><strong>P/E Ratio:</strong> {stats.PERatio}</p></Col>
                            <Col md={4}><p><strong>PEG Ratio:</strong> {stats.PEGRatio}</p></Col>
                        </Row>

                        <Row>
                            <Col md={4}><p><strong>Beta:</strong> {stats.Beta}</p></Col>
                            <Col md={4}><p><strong>52 Week High:</strong> {stats["52WeekHigh"]}</p></Col>
                            <Col md={4}><p><strong>52 Week Low:</strong> {stats["52WeekLow"]}</p></Col>
                        </Row>

                        <Row>
                            <Col md={4}><p><strong>50 Day Moving Avg:</strong> {stats["50DayMovingAverage"]}</p></Col>
                            <Col md={4}><p><strong>200 Day Moving Avg:</strong> {stats["200DayMovingAverage"]}</p></Col>
                            <Col md={4}><p><strong>Dividend Date:</strong> {stats.DividendDate}</p></Col>
                        </Row>

                        <Row>
                            <Col md={4}><p><strong>Ex-Dividend Date:</strong> {stats.ExDividendDate}</p></Col>
                            <Col md={4}><p><strong>Shares Outstanding:</strong> {parseFloat(stats.SharesOutstanding).toLocaleString()}</p></Col>
                            <Col md={4}><p><strong>Trailing P/E:</strong> {stats.TrailingPE}</p></Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="options" title="Options">
                        {/* Ensure bs is defined and contains the necessary properties */}
                        {bs && bs.contractID ? (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>Contract ID</strong></td>
                                        <td>{bs.contractID}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Symbol</strong></td>
                                        <td>{bs.symbol}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Expiration</strong></td>
                                        <td>{bs.expiration}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Strike Price</strong></td>
                                        <td>{bs.strike}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Type</strong></td>
                                        <td>{bs.type}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Last Price</strong></td>
                                        <td>{bs.last}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Mark</strong></td>
                                        <td>{bs.mark}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Bid</strong></td>
                                        <td>{bs.bid}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Bid Size</strong></td>
                                        <td>{bs.bid_size}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Ask</strong></td>
                                        <td>{bs.ask}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Ask Size</strong></td>
                                        <td>{bs.ask_size}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Volume</strong></td>
                                        <td>{bs.volume}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Open Interest</strong></td>
                                        <td>{bs.open_interest}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Implied Volatility</strong></td>
                                        <td>{bs.implied_volatility}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Delta</strong></td>
                                        <td>{bs.delta}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Gamma</strong></td>
                                        <td>{bs.gamma}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Theta</strong></td>
                                        <td>{bs.theta}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Vega</strong></td>
                                        <td>{bs.vega}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Rho</strong></td>
                                        <td>{bs.rho}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        ) : (
                            <p>No options data available.</p>
                        )}
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    );
};

export default StockInformation;
